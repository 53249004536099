<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="subtitle">
          <p>行稳致远，进而有为——2021年有为信通团建暨客户答谢会圆满落幕~</p>
          <p>发表时间:2021-04-20 15:41</p>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img1.png"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              2021年4月17-18日，为表达对客户朋友对公司业务的大力支持，和提高员工的团队凝聚力，有为信通在北京天毓山庄特举办“行稳致远，进而有为——2021年有为信通团建暨客户答谢会”，到会者近200人，大家同聚一堂，共享盛宴！
            </span>
          </div>
        </div>
        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title1.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img2.png"
              alt=""
            />
          </div>
        </div>
        <div class="textdescribe">
          <span
            >总经理客观总结了在2020年疫情影响下，公司的发展情况，肯定了大家共同努力所取得的成绩，重点强调创新驱动企业发展，新的一年，各位同仁应该一如既往的坚守“一分耕耘一分收获，不驰于空想、不骛于虚声，聚力攻坚，踏实奋斗，无论外界环境如何变化，我们都要迎难而上，去争取更大的胜利！”，会场响出了热烈地掌声！</span
          >
        </div>
        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title2.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img3.png"
              alt=""
            />
          </div>
        </div>
        <div class="textdescribe">
          <span
            >总经理客观总结了在2020年疫情影响下，公司的发展情况，肯定了大家共同努力所取得的成绩，重点强调创新驱动企业发展，新的一年，各位同仁应该一如既往的坚守“一分耕耘一分收获，不驰于空想、不骛于虚声，聚力攻坚，踏实奋斗，无论外界环境如何变化，我们都要迎难而上，去争取更大的胜利！”，会场响出了热烈地掌声！</span
          >
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title3.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img4.png"
              alt=""
            />
          </div>
        </div>
        <div class="textdescribe">
          <span
            >嘉宾张先生做了“新经济下公司的战略机遇”的介绍，从国际形势、国内形势、疫情等方面，客观分析了行业形势，以及在该形势下，带给公司的发展机遇，机遇出现的时候，我们一定不能错过~</span
          >
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title4.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img5.png"
              alt=""
            />
          </div>
          <div class="img-title">
            研发团队工作态度兢兢业业，生活态度积极乐观，一首《追梦赤子心》，诠释了他们的青春与活力~
          </div>
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title5.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img6.png"
              alt=""
            />
          </div>
          <div class="img-title">
            企业核心应该立足产品，优质的产品是一切的基础~
          </div>
        </div>
        <div class="textdescribe">
          <span
            >解决方案工程师介绍了“有为2021年公司产品线”，在有为产品定位“更加宽广的格局、先进技术迅速导入、积极主动被集成”指导下，公司自主研发了空间智能化平台、智慧能耗管控APP、智慧车位管控APP、协同办公软件等多款优质产品。</span
          >
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title6.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img7.png"
              alt=""
            />
          </div>
        </div>
        <div style="text-align: center" class="textdescribe">
          <span
            >创新中心总监提出了公司发展规划，将主要立足于产品优势、市场优势、技术优势、管理优势等方面，通过不懈努力和技术创新，带动公司发展更上一层楼~</span
          >
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title7.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img8.png"
              alt=""
            />
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img9.png"
              alt=""
            />
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img10.png"
              alt=""
            />
            <img
              style="margin: 0"
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img11.png"
              alt=""
            />
            <div class="img-title">
              公司也为参会人员准备了精彩的抽奖环节，谁能成为幸运儿，获取精美奖品，太值得期待了！
            </div>
          </div>
        </div>

        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/Thankyoumeeting/bg-title8.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img12.png"
              alt=""
            />
            <img
            style="margin-bottom:0px"
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img13.png"
              alt=""
            />
            <div class="img-title">
              大家在欢声笑语中，享受美食，享受欢乐，美好的时刻就在你我之间~
            </div>
            <img
              src="../../assets/image/enterpriseNews/Thankyoumeeting/img14.png"
              alt=""
            />
          </div>
        </div>
        <div style="text-align: center;margin-bottom: 40px" class="textdescribe">
          <span
            >通过本次活动，更加明确了公司发展方向，更加凝聚了同事的奋斗之心，更加让客户朋友们感受到有为信通的蓬勃朝气，相信我们一定能创造出更加辉煌灿烂的明天！</span
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #aad4ee;
      height: 220px;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #f4effd;
        height: 180px;
        color: #8735e4;
        font-size: 18px;
        padding: 40px 48px 0 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          margin: 22px 0 15px 0;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0 !important;
        }
        // img:nth-child(3) {
        //   margin: 0;
        // }
      }
    }
    .bg-title {
      height: 28px;
      margin: 50px 0 50px 0;
      img {
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>